<template>
    <div class="v-modal__body requisites">
        <button type="button" class="close-modal"
                @click="$modal.hide('Requisites')">×
        </button>
        <h2 class="h2">Реквизиты</h2>
        <ul class="requisites__list">
            <li class="requisites__item">
                {{payerData.name}}
            </li>
            <li class="requisites__item">
                Юридический адрес: {{payerData.legal_address}}
            </li>
            <li class="requisites__item">
                Фактический адрес: {{payerData.actual_address}}
            </li>
            <li v-if="payerData.contact_person" class="requisites__item">
                Контактное лицо: {{payerData.contact_person}}
            </li>
            <li v-if="payerData.phone" class="requisites__item">
                Телефон: {{payerData.phone}}
            </li>
            <li class="requisites__item">
                <span class="font-w600">ИНН:</span> {{payerData.inn}}
            </li>
            <li v-if="payerData.ogrnip" class="requisites__item">
                <span class="font-w600">ОГРНИП:</span> {{payerData.ogrnip}}
            </li>
            <li v-if="payerData.kpp" class="requisites__item">
                <span class="font-w600">КПП:</span> {{payerData.kpp}}
            </li>
            <li v-if="payerData.ogrn" class="requisites__item">
                <span class="font-w600">ОГРН:</span> {{payerData.ogrn}}
            </li>
        </ul>
        <button class="v-btn v-btn--lightgray requisites__btn" @click="editPayer">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5.25 12.7598L8.55975 12.7485L15.7837 5.59355C16.0672 5.31005 16.2232 4.93355 16.2232 4.53305C16.2232 4.13255 16.0672 3.75605 15.7837 3.47255L14.5942 2.28305C14.0272 1.71605 13.038 1.71905 12.4755 2.2808L5.25 9.4373V12.7598ZM13.5337 3.34355L14.7255 4.5308L13.5278 5.7173L12.3382 4.52855L13.5337 3.34355ZM6.75 10.0628L11.2725 5.58305L12.462 6.77255L7.94025 11.2508L6.75 11.2545V10.0628Z"
                    fill="black"/>
                <path
                    d="M3.75 15.75H14.25C15.0773 15.75 15.75 15.0773 15.75 14.25V7.749L14.25 9.249V14.25H6.1185C6.099 14.25 6.07875 14.2575 6.05925 14.2575C6.0345 14.2575 6.00975 14.2507 5.98425 14.25H3.75V3.75H8.88525L10.3853 2.25H3.75C2.92275 2.25 2.25 2.92275 2.25 3.75V14.25C2.25 15.0773 2.92275 15.75 3.75 15.75Z"
                    fill="black"/>
            </svg>
            <span>Редактировать</span>
        </button>
    </div>
</template>

<script>
    import session from '@/api/session';

    export default {
        name: "Requisites",
        props: {
            payer: {
                type: Object,
                required: true
            },
            openEditModal: {
                type: Function,
                required: true
            }
        },
        data() {
            return {
                payerData: {}
            }
        },
        methods: {
            editPayer() {
                this.$modal.hide('Requisites');
                this.openEditModal(this.payerData);
            }
        },
        async mounted() {
            const response = await session.get(`/api/v1/payer/${this.payer.id}/?type=${this.payer.type}`);
            this.payerData = response.data;
        }
    }
</script>

<style lang="scss">
    .v--modal-overlay[data-modal="Requisites"] {
        .v--modal-box {
            max-width: 600px !important;
            margin: 0 auto;
        }
    }

    .requisites {
        color: #000;

        &__btn {
            margin: 25px auto 0;
            display: flex;
            align-items: center;

            & > svg {
                margin-right: 5px;
            }
        }
    }
</style>
